import React from 'react';
import axios from 'axios';

// 创建一个 Axios 实例
const instance = axios.create({
  baseURL: '/apis',
  timeout: 50000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
});

// 设置请求拦截器
instance.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 设置响应拦截器
instance.interceptors.response.use(
  res => {
    console.log('res', res.data);
    return res.data;
  },
  err => {
    console.log('err', err);
    if (!err.response) React.$messageApi.error('网络异常');
    return Promise.reject(err);
  }
);

export default instance;
