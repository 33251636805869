import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Spin } from 'antd';

// 预加载
import CodeView from '../views/code';
import ForeignView from '../views/foreign';
import ReceiveView from '../views/receive';
import RongyanView from '../views/rongyan';

// 懒加载
let Error = React.lazy(() => import('../views/error'));

function Loading() {
  return <Spin delay="200" size="small" fullscreen></Spin>;
}

export default function RouterComponent() {
  return (
    <Routes>
      <Route path="/" exact element={<Navigate to="/code"></Navigate>}></Route>
      <Route path="/code" element={<CodeView />}></Route>
      <Route path="/foreign" element={<ForeignView />}></Route>
      <Route path="/receive" element={<ReceiveView />}></Route>
      <Route path="/rongyan" element={<RongyanView />}></Route>
      <Route
        path="*"
        element={
          <Suspense fallback={Loading()}>
            <Error />
          </Suspense>
        }
      ></Route>
    </Routes>
  );
}
