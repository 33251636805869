import React from 'react';
import { ConfigProvider, message } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import RouterComponent from './router';

function App() {
  const [messageApi, contextHolder] = message.useMessage();
  React.$messageApi = messageApi;
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        cssVar: true,
      }}
    >
      {contextHolder}
      <RouterComponent></RouterComponent>
    </ConfigProvider>
  );
}

export default App;
