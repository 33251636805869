import React from 'react';
import { Button, Result } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

function CodeView() {
  const localUrl = window.location.href;
  const queryString = localUrl.split('?')[1];

  useEffect(() => {
    document.title = '获取用户';
    getUrlSync(); // 获取Url
  });

  // 获取Url
  function getUrlSync() {
    React.$request
      .get(`/wx/apiMq/notify?${queryString}`)
      .then(res => {})
      .catch(err => {});
  }

  return (
    <div>
      <Result
        icon={<WechatOutlined style={{ fontSize: 60, color: '#1AAD19' }} />}
        title="正在跳转到嘟好停..."
        subTitle="如未自动打开微信请点击下方按钮"
        extra={<Button type="primary">打开微信</Button>}
      />
    </div>
  );
}

export default CodeView;
