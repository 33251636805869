import React from 'react';
import moment from 'moment';
import { Button, Form, Flex, Input, Result, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { addQueryString } from '../utils';

const appKey = '601915942c6fe';
const appSecret = '98551c89c6c78563dc561b095e3a860d';
const type = 'officialAccounts';

const localUrl = window.location.href;
const searchParams = new URLSearchParams(window.location.search);

function ReceiveView() {
  const [activityDetail, setActivityDetail] = useState(null);
  const [appid] = useState(searchParams.get('appid'));
  const [bgImg, setBgImg] = useState('');
  const [couponAutoId, setCouponAutoId] = useState(0);
  const [code] = useState(searchParams.get('code'));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [openId, setOpenId] = useState('');
  const [result, setResult] = useState({
    status: '',
    title: '',
    subTitle: '',
    extra: '',
  });
  const [saasId, setSaasId] = useState(0);
  const [sourceType] = useState(searchParams.get('sourceType'));
  const [second, setSecond] = useState(0);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    if (!appid || !sourceType) {
      setResult({
        status: 'error',
        title: '获取code失败',
        subTitle: '缺少必要参数',
        extra: '',
      });
    } else if (!code) {
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(
        localUrl
      )}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
    } else {
      getActivityList(appid, code); // 查询当前正在进行的活动
    }
  }, [appid, code, sourceType]);

  useEffect(() => {
    if (!activityDetail) return;
    if (activityDetail.id) setCouponAutoId(activityDetail.id);
    if (activityDetail.name) document.title = activityDetail.name;
  }, [activityDetail]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (second > 0) setSecond(prevState => prevState - 1);
      else clearInterval(timer);
    }, 1000);
    return () => clearInterval(timer);
  }, [second]);

  // 查询当前正在进行的活动
  function getActivityList(appid, code) {
    setSpinning(true);
    let postData = {
      appid: appid,
      code: code,
    };
    let params = {
      appKey,
      postData: JSON.stringify(postData),
      reqTime: moment().valueOf().toString().substring(0, 10),
      type,
    };
    const sign = React.$md5(addQueryString(params) + appSecret);
    React.$request
      .post('/wx/officialAccounts/activityList', { ...params, sign })
      .then(res => {
        if (res.data === null) {
          setResult({
            status: 'warning',
            title: '获取活动失败',
            subTitle: res.msg,
            extra: (
              <Button
                type="primary"
                onClick={() =>
                  (window.location.href = `${
                    localUrl.split('?')[0]
                  }?appid=${appid}&sourceType=${sourceType}`)
                }
              >
                重新获取
              </Button>
            ),
          });
        } else {
          if (res.data.openId) setOpenId(res.data.openId);
          if (res.data.image) setBgImg(res.data.image);
          if (res.data.saasId) setSaasId(res.data.saasId);
          if (res.data.couponActivities) {
            setActivityDetail(res.data.couponActivities);
          }
        }
      })
      .catch(err => {})
      .finally(() => {
        setSpinning(false);
      });
  }

  // 公众号获取手机验证码
  function getPhoneCode() {
    form
      .validateFields(['phoneNum'])
      .then(res => {
        const key = 'code';
        React.$messageApi.open({
          key,
          type: 'loading',
          content: '正在发送',
        });
        let postData = {
          appid: appid,
          couponAutoId: couponAutoId.toString(),
          openId: openId,
          phone: res.phoneNum,
        };
        let params = {
          appKey,
          postData: JSON.stringify(postData),
          reqTime: moment().valueOf().toString().substring(0, 10),
          type,
        };
        const sign = React.$md5(addQueryString(params) + appSecret);
        React.$request
          .post('/wx/officialAccounts/getPhoneCode', { ...params, sign })
          .then(res => {
            if (res.status === 'SUCCESS') {
              setSecond(60);
              React.$messageApi.open({
                key,
                type: 'success',
                content: res.msg,
              });
            } else {
              setSecond(5);
              React.$messageApi.destroy();
              setResult({
                status: 'info',
                title: '发送失败',
                subTitle: res.msg,
                extra: [
                  <Button
                    type="primary"
                    onClick={() => getUrllink('/pages/index/index')}
                  >
                    前往查看
                  </Button>,
                  <Button
                    onClick={() =>
                      setResult({
                        status: '',
                        title: '',
                        subTitle: '',
                        extra: '',
                      })
                    }
                  >
                    返回重试
                  </Button>,
                ],
              });
            }
          })
          .catch(err => {})
          .finally(() => {});
      })
      .catch(err => {});
  }

  // 输入验证码领取优惠券
  function receiveCoupon() {
    form
      .validateFields()
      .then(res => {
        setLoading(true);
        let postData = {
          appid: appid,
          couponAutoId: couponAutoId.toString(),
          openId: openId,
          phone: res.phoneNum,
          saasId: saasId.toString(),
          sourceType: sourceType,
          verificationCode: res.code,
        };
        let params = {
          appKey,
          postData: JSON.stringify(postData),
          reqTime: moment().valueOf().toString().substring(0, 10),
          type,
        };
        const sign = React.$md5(addQueryString(params) + appSecret);
        React.$request
          .post('/wx/officialAccounts/receiveCoupon', { ...params, sign })
          .then(res => {
            if (res.status === 'SUCCESS') setActivityDetail(null);
            setResult({
              status: res.status === 'SUCCESS' ? 'success' : 'info',
              title: res.status === 'SUCCESS' ? '领取成功' : '领取失败',
              subTitle: res.msg,
              extra:
                res.status === 'SUCCESS' ? (
                  <Button
                    type="primary"
                    onClick={() => getUrllink('/pages/index/index')}
                  >
                    前往查看
                  </Button>
                ) : (
                  [
                    <Button
                      type="primary"
                      onClick={() => getUrllink('/pages/index/index')}
                    >
                      前往查看
                    </Button>,
                    <Button
                      onClick={() =>
                        setResult({
                          status: '',
                          title: '',
                          subTitle: '',
                          extra: '',
                        })
                      }
                    >
                      返回重试
                    </Button>,
                  ]
                ),
            });
          })
          .catch(err => {})
          .finally(() => {
            setTimeout(() => {
              setLoading(false);
            }, 4000);
          });
      })
      .catch(err => {});
  }

  // 跳转嘟好停小程序的连接
  function getUrllink(pages, query = '') {
    const key = 'link';
    React.$messageApi.open({
      key,
      type: 'loading',
      content: '正在获取',
    });
    let postData = {
      exTime: moment().add(1, 'days').valueOf().toString().substring(0, 10),
      pages: pages,
      query: query,
      saasId: saasId,
    };
    let params = {
      appKey,
      postData: JSON.stringify(postData),
      reqTime: moment().valueOf().toString().substring(0, 10),
      type,
    };
    const sign = React.$md5(addQueryString(params) + appSecret);
    React.$request
      .post('/index/urllink', { ...params, sign })
      .then(res => {
        if (res.status === 'SUCCESS' && res.data.url_link) {
          window.location.replace(res.data.url_link);
        }
        React.$messageApi.open({
          key,
          type: res.status === 'SUCCESS' ? 'success' : 'error',
          content: res.msg,
        });
      })
      .catch(err => {})
      .finally(() => {});
  }

  return (
    <>
      <Spin spinning={spinning} fullscreen></Spin>
      {result.status !== '' ? (
        <Result
          status={result.status}
          title={result.title}
          subTitle={result.subTitle}
          extra={result.extra}
        />
      ) : (
        <Flex
          style={{
            height: '100vh',
            backgroundImage: `url(${bgImg})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
          justify="center"
          align="center"
        >
          <Form
            style={{
              position: 'relative',
              width: '80%',
              height: '100%',
            }}
            form={form}
            size="large"
          >
            <div className="receive-card">
              <Form.Item
                name="phoneNum"
                rules={[
                  {
                    required: true,
                    message: '手机号为必填项！',
                  },
                  {
                    validator: (_, value) => {
                      const reg = /^1\d{10}$/;
                      return !value || reg.test(value)
                        ? Promise.resolve()
                        : Promise.reject('手机号格式不正确！');
                    },
                  },
                ]}
              >
                <Input placeholder="请输入手机号" maxLength="11" />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: '验证码为必填项！',
                  },
                ]}
              >
                <Input
                  placeholder="请输入验证码"
                  maxLength="6"
                  suffix={
                    <Button
                      type="text"
                      size="small"
                      disabled={second > 0}
                      onClick={getPhoneCode}
                    >
                      {second === 0 ? '获取验证码' : `重新发送(${second})`}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="receive-button"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  danger
                  block
                  loading={loading}
                  onClick={receiveCoupon}
                >
                  立即领取
                </Button>
              </Form.Item>
              <div style={{ color: '#79573e' }}>
                <ul
                  style={{
                    fontSize: '0.9em',
                    paddingInlineStart: '20px',
                    listStyleType: 'decimal',
                  }}
                >
                  <li>
                    本券只限新用户可领取，可享受直接抵扣停车费，不能与其他优惠券同时使用；
                  </li>
                  <li>在“我的-优惠券”查看已领取的优惠券；</li>
                  <li>优惠券领取后30天内有效，过期无效；</li>
                  <li>客服热线：400-632-1688。</li>
                </ul>
              </div>
            </div>
          </Form>
        </Flex>
      )}
    </>
  );
}

export default ReceiveView;
