import React from 'react';
import moment from 'moment';
import {
  Button,
  Form,
  Flex,
  Input,
  Image,
  Radio,
  Result,
  Spin,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { addQueryString } from '../utils';
import bgImg from '../assets/img/official_account_bg.png';

const appKey = '601915942c6fe';
const appSecret = '98551c89c6c78563dc561b095e3a860d';
const type = 'officialAccounts';
const fiftyUrl =
  'https://base.95516.com/s/wl/WebAPP/helpAgree/page/help/shareRutineHelp.html?params=ewogICJyZWRpcmVjdFBhZ2UiIDogImh0dHBzJTNBJTJGJTJGeWlubGlhbi5yYXlvc2VydmljZS5jb20lMkZwdWJsaXNoJTJGTUJZTTI0MDgwMjkwNzMwMDAwMDRfeXVud2FuZzUwLmh0bWwiLAogICJlbmNyeXB0QXBwSWQiIDogIjJjYzI3NTM5YzE1ZjNkY2QiCn0=';
const thousandUrl =
  'https://base.95516.com/s/wl/WebAPP/helpAgree/page/help/shareRutineHelp.html?params=ewogICJyZWRpcmVjdFBhZ2UiIDogImh0dHBzJTNBJTJGJTJGeWlubGlhbi5yYXlvc2VydmljZS5jb20lMkZwdWJsaXNoJTJGTUJZTTI0MDgwMjkwNzMwMDAwMDRfeXVud2FuZzEwMDAuaHRtbCIsCiAgImVuY3J5cHRBcHBJZCIgOiAiMmNjMjc1MzljMTVmM2RjZCIKfQ==';

function RongyanView() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [result, setResult] = useState({
    status: '',
    title: '',
    subTitle: '',
    extra: '',
  });
  const [saasId] = useState(0);
  const [second, setSecond] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const [visible, setVisible] = useState(false);

  const guideName = Form.useWatch(
    values => `${values.sendMoney + '元' || ''}权益包使用指引`,
    form
  );
  const guideImgUrl = Form.useWatch(
    values =>
      `http://img.cneasypark.com/miniappIcon/activity/ry_quanyi_${
        values.sendMoney || '50'
      }.png`,
    form
  );

  useEffect(() => {
    getActivityList(saasId); // 查询当前正在进行的活动
  }, [saasId]);

  useEffect(() => {
    if (options.length > 0) form.setFieldValue('sendMoney', options[0].value);
  }, [form, options]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (second > 0) setSecond(prevState => prevState - 1);
      else clearInterval(timer);
    }, 1000);
    return () => clearInterval(timer);
  }, [second]);

  // 查询当前正在进行的活动
  function getActivityList(saasId) {
    setSpinning(true);
    let postData = {
      saasId: saasId,
    };
    let params = {
      appKey,
      postData: JSON.stringify(postData),
      reqTime: moment().valueOf().toString().substring(0, 10),
      type,
    };
    const sign = React.$md5(addQueryString(params) + appSecret);
    React.$request
      .post('/wx/officialAccounts/findBenefitsPackage', { ...params, sign })
      .then(res => {
        if (res.data === null) {
          setResult({
            status: 'warning',
            title: '获取活动失败',
            subTitle: res.msg,
            extra: <Button type="primary">重新获取</Button>,
          });
        } else {
          let result = res.data.map(v => {
            return { label: v.name, value: Number(v.sendMoney) };
          });
          setOptions(result);
        }
      })
      .catch(err => {})
      .finally(() => {
        setSpinning(false);
      });
  }

  // 公众号获取手机验证码
  function getPhoneCode() {
    form
      .validateFields(['phoneNum'])
      .then(res => {
        const key = 'code';
        React.$messageApi.open({
          key,
          type: 'loading',
          content: '正在发送',
        });
        let postData = {
          saasId: saasId,
          phone: res.phoneNum,
        };
        let params = {
          appKey,
          postData: JSON.stringify(postData),
          reqTime: moment().valueOf().toString().substring(0, 10),
          type,
        };
        const sign = React.$md5(addQueryString(params) + appSecret);
        React.$request
          .post('/wx/officialAccounts/getbBenefitsPackageCode', {
            ...params,
            sign,
          })
          .then(res => {
            if (res.status === 'SUCCESS') {
              setSecond(60);
              React.$messageApi.open({
                key,
                type: 'success',
                content: res.msg,
              });
            } else {
              setSecond(5);
              React.$messageApi.destroy();
              setResult({
                status: 'info',
                title: '发送失败',
                subTitle: res.msg,
                extra: [
                  <Button type="primary">前往查看</Button>,
                  <Button
                    onClick={() =>
                      setResult({
                        status: '',
                        title: '',
                        subTitle: '',
                        extra: '',
                      })
                    }
                  >
                    返回重试
                  </Button>,
                ],
              });
            }
          })
          .catch(err => {})
          .finally(() => {});
      })
      .catch(err => {});
  }

  // 输入验证码领取优惠券
  function receiveCoupon() {
    form
      .validateFields()
      .then(res => {
        setLoading(true);
        const { code, phoneNum, sendMoney } = res;
        let postData = {
          phone: phoneNum,
          saasId: saasId,
          sendMoney: sendMoney,
          verificationCode: code,
        };
        let params = {
          appKey,
          postData: JSON.stringify(postData),
          reqTime: moment().valueOf().toString().substring(0, 10),
          type,
        };
        const sign = React.$md5(addQueryString(params) + appSecret);
        React.$request
          .post('/wx/officialAccounts/getbBenefitsPackage', { ...params, sign })
          .then(res => {
            React.$messageApi
              .open({
                type: res.status === 'SUCCESS' ? 'success' : 'error',
                content: res.msg,
              })
              .then(() => {
                if (res.status === 'SUCCESS' && sendMoney === 50) {
                  window.location.replace(fiftyUrl);
                } else if (res.status === 'SUCCESS' && sendMoney === 1000) {
                  window.location.replace(thousandUrl);
                }
              });
          })
          .catch(err => {})
          .finally(() => {
            setTimeout(() => {
              setLoading(false);
            }, 4000);
          });
      })
      .catch(err => {});
  }

  return (
    <>
      <Spin spinning={spinning} fullscreen></Spin>
      {result.status !== '' ? (
        <Result
          status={result.status}
          title={result.title}
          subTitle={result.subTitle}
          extra={result.extra}
        />
      ) : (
        <Flex
          style={{
            height: '100vh',
            backgroundImage: `url(${bgImg})`,
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
          }}
          justify="center"
          align="center"
        >
          <Form
            style={{
              position: 'relative',
              width: '80%',
              height: '100%',
            }}
            form={form}
            size="large"
          >
            <div className="receive-card">
              <Form.Item
                name="sendMoney"
                rules={[
                  {
                    required: true,
                    message: '类型为必选项！',
                  },
                ]}
              >
                <Radio.Group
                  options={options}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                name="phoneNum"
                rules={[
                  {
                    required: true,
                    message: '手机号为必填项！',
                  },
                  {
                    validator: (_, value) => {
                      const reg = /^1\d{10}$/;
                      return !value || reg.test(value)
                        ? Promise.resolve()
                        : Promise.reject('手机号格式不正确！');
                    },
                  },
                ]}
              >
                <Input placeholder="请输入手机号" maxLength="11" />
              </Form.Item>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: '验证码为必填项！',
                  },
                ]}
              >
                <Input
                  placeholder="请输入验证码"
                  maxLength="6"
                  suffix={
                    <Button
                      type="text"
                      size="small"
                      disabled={second > 0}
                      onClick={getPhoneCode}
                    >
                      {second === 0 ? '获取验证码' : `重新发送(${second})`}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  block
                  loading={loading}
                  onClick={receiveCoupon}
                >
                  前往领取
                </Button>
              </Form.Item>
              <div style={{ color: '#79573e' }}>
                <ul
                  style={{
                    fontSize: '0.9em',
                    paddingInlineStart: '20px',
                    listStyleType: 'decimal',
                  }}
                >
                  <li>
                    本权益仅限新用户参与，参与后请扫码前往“云闪付”领取，具体请查看
                    <Typography.Link onClick={() => setVisible(true)}>
                      《{guideName}》
                    </Typography.Link>
                    ；
                  </li>
                  <li>
                    福利大礼包含加油、洗车、充电等优惠，【权益可领取3个月，每自然月可领取一次】参与后请在30天内领取，超时未领取视为放弃权益，不予补发；
                  </li>
                  <li>
                    领取后礼包权益30天内有效，单笔消费只能使用1张，过期未使用不退不换，本福利不与平台其他优惠、折扣、套餐叠加使用；
                  </li>
                  <li>
                    本权益由广州荣焰科技有限公司提供，领取有疑问请联系13392673544（微信同号）。
                  </li>
                </ul>
              </div>
            </div>
          </Form>
          <Image
            style={{
              display: 'none',
            }}
            src={guideImgUrl}
            preview={{
              visible,
              src: guideImgUrl,
              onVisibleChange: value => setVisible(value),
            }}
          />
        </Flex>
      )}
    </>
  );
}

export default RongyanView;
