import 'normalize.css';
import './assets/scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import md5 from 'js-md5';
import request from './utils/request';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

React.$md5 = md5;
React.$request = request;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename="/build/">
    <App />
  </BrowserRouter>
);
