import React from 'react';
import { Button, Result } from 'antd';
import { WechatOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

function ForeignView() {
  useEffect(() => {
    document.title = '获取用户';
    getUrlSync(); // 获取Url
  });

  // 获取Url
  function getUrlSync() {
    React.$request
      .get('/index/foreign/urllink')
      .then(res => {
        if (res.status === 'SUCCESS' && res.data && res.data.url_link) {
          window.location.href = res.data.url_link;
        }
      })
      .catch(err => {});
  }

  return (
    <div>
      <Result
        icon={<WechatOutlined style={{ fontSize: 60, color: '#1AAD19' }} />}
        title="正在跳转到嘟好停..."
        subTitle="如未自动打开微信请点击下方按钮"
        extra={
          <Button type="primary" onClick={getUrlSync}>
            打开微信
          </Button>
        }
      />
    </div>
  );
}

export default ForeignView;
